import React, { useState } from "react";
import logo from './logo.svg';
import './App.css';
import firebase from "firebase/app";
import axios from 'axios';
import "firebase/auth";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { FirebaseAuthProvider, IfFirebaseAuthed, IfFirebaseUnAuthed } from "@react-firebase/auth";
import { Button, TextField } from '@material-ui/core';
import { config } from './firebase';

//const API_URL = "http://localhost:8080";//local testing
const API_URL = "https://api.puttr.co";

const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
  ],
};

firebase.initializeApp(config);

function App() {

  const [code, setCode] = useState("");
  const [mess, setMess] = useState("");
  const [loading, setLoading] = useState(false);

  const changeField = (e) => {
    const str = e.target.value.toUpperCase();
    if (str.length < 7){
      setCode(e.target.value.toUpperCase());
    }
  };

  const submitCode = async (user) => {
    //console.log("user", JSON.stringify(user));
    setLoading(true);
    
    if (code.length === 6){
      const url = API_URL + "/tv/confirmCode/";
      const token = await firebase.auth().currentUser.getIdToken();
      const opts = { headers: { Authorization: `Bearer ${token}`} };

      const data = {
        userCode: code
      };

      axios.post(url, data, opts)
      .then(function (response) {
        console.log("response", response.data);
        if (response.data.error){//there was an error on code
          setMess(response.data.error);
        }
        else {//api returned valid result

        }
      })
      .catch(function (error) {
        console.log(error);
      });
    }
    else {
      //console.log("code must be 6 chars");
      setMess("Code must be 6 characters");
    }
    setLoading(false);

  }

  const renderSubmitButton = (user) => {
    if (loading){
      return(
        <div className="App-label">Loading...</div>
      );
    }
    return(
      <div style={{padding:10}}>
        <Button variant="contained" color="primary" onClick={() => {submitCode(user)}}>SUBMIT</Button>
      </div>
    );
  }

  return (

    <FirebaseAuthProvider firebase={firebase} {...config}>
      <div className="App">
        <header className="App-header">
          <img src={logo} className="Static-logo" alt="logo" />
          <p>
            Welcome to the Puttr TV registration page!
          </p>

          <IfFirebaseUnAuthed>
            <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
          </IfFirebaseUnAuthed>

          <IfFirebaseAuthed>
          {({ isSignedIn, user, providerId }) => {
            return (
              <div>
                <div className="App-label">Please enter 6 digit code from TV to authenticate</div>
                <div>
                  <TextField
                    id="tvCode"
                    label="6 DIGIT CODE"
                    value={code}
                    onChange={changeField}
                    inputProps={{min: 0, style: { textAlign: 'center' }}}
                  />
                </div>
                <div>
                  {renderSubmitButton(user)}
                </div>
                <div style={{ color:'red'}}>
                  {mess}
                </div>
                <div style={{padding:10}}>
                  <Button variant="contained" color="secondary" onClick={() => firebase.auth().signOut()}>Sign-out</Button>
                </div>
              </div>
            );
          }}
          </IfFirebaseAuthed>
        </header>
      </div>
    </FirebaseAuthProvider>
  );
}

export default App;
